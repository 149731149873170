<template>
  <el-row>
    <el-row class="custom--top-btn-wrapper" type="flex" :gutter="20">
      <el-col class="left-btn-group" :span="18">
        <GgdCard class="custom--GgdCard" background="#409EFF" title="今日流水" :content="dayAccount"></GgdCard>
        <GgdCard class="custom--GgdCard" background="#F19149" title="今日成单" :content="dayOrder" unit="个"></GgdCard>
        <GgdCard class="custom--GgdCard" background="#F19149" title="领取机会数" :content="opporTotalCount"
                 unit="个"></GgdCard>
        <GgdCard class="custom--GgdCard" background="#FF88A7" title="拨打时长" :content="formateTime(sumTime)"></GgdCard>
        <GgdCard class="custom--GgdCard" background="#F19149" title="平均单通话时长" :content="formateTime(avgTime)"></GgdCard>
        <GgdCard class="custom--GgdCard" background="#7B5EEA" title="今日拨打量" :content="dailUp" unit="个"></GgdCard>
      </el-col>
      <el-col class="right-btn-group" :offset="1" :span="6">
        <el-button-group>
          <el-button type="primary" size="large"
                     @click="toHome"
                     :style="{margin:' 5px ',background:'red',
                     borderColor:'red',height:'62px',
                     borderRadius:'10px'}">
            <i class="el-icon-switch-button" :style="{fontSize:'22px'}"></i><br>
            回到主页
          </el-button>
          <el-button type="primary" size="large"
                     @click="reload"
                     :style="{margin:' 5px ',background:'#6D88FD',borderColor:'#6D88FD',height:'62px',borderRadius:'10px'}">
            领取库存<br>
            <span>{{ stockCount }}/{{ stockTotal }}</span>
          </el-button>
        </el-button-group>
      </el-col>
    </el-row>
    <el-form inline label-width="120px" class="form-query">
      <el-form-item label="唯一配置">
        <el-select v-model="searchData.possessId" size="mini" class="search-input" filterable placeholder="请选择">
          <el-option label="全部" :value="null"></el-option>
          <el-option v-for="item in possessList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="机会来源-分类" v-if="zebj()">
        <el-input v-model="searchData.classify" size="mini" class="search-input"/>
      </el-form-item>
      <el-form-item label="项目类别">
        <el-input v-model="searchData.subjectName" size="mini" class="search-input"/>
      </el-form-item>
      <el-form-item label="领取次数">
        <template #default>
            <el-input-number :step-strictly="true" :step="1" :min="0" :max="searchData.receiveEnd" size="mini" v-model="searchData.receiveStart" style="width: 30%"></el-input-number> 至
            <el-input-number :step-strictly="true" :step="1" :min="searchData.receiveStart" :max="9999" size="mini" v-model="searchData.receiveEnd" style="width: 30%"></el-input-number>
        </template>
      </el-form-item>
      <el-form-item label="上传日期">
        <el-date-picker class="search-input" size="mini" style="width: 200px" v-model="uploadTime"
                        type="datetimerange" range-separator="至" value-format="yyyy-MM-dd HH:mm:ss"
                        start-placeholder="上传日期开始" :default-time="['00:00:00', '23:59:59']"
                        end-placeholder="上传日期结束">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="放弃时间">
        <el-date-picker class="search-input" size="mini" style="width: 200px" v-model="backStoreTimeStr" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至"
                        start-placeholder="放弃日期开始" :default-time="['00:00:00', '23:59:59']"
                        end-placeholder="放弃日期结束">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="最后拨打时间">
        <el-date-picker class="search-input" size="mini" style="width: 200px" v-model="dailTimeArr" value-format="yyyy-MM-dd HH:mm:ss" unlink-panels type="datetimerange" range-separator="至"
                        start-placeholder="开始" :default-time="['00:00:00', '23:59:59']"
                        end-placeholder="结束">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <el-button class="search-btn" size="mini" type="primary" @click="findRepertoryList">搜索</el-button>
    <el-row>
      <el-table :data="stockTableData" stripe height="680" style="width: 100%" v-loading="loading">
        <el-table-column prop="id" align="center" label="机会ID"></el-table-column>
        <el-table-column prop="telephone" align="center" label="机会号码"></el-table-column>
        <el-table-column prop="subjectName" align="center" label="项目类别"></el-table-column>
        <el-table-column prop="backTime" align="center" label="回库时间"></el-table-column>
        <!--        <el-table-column prop="goodsName" align="center" label="课程名称"></el-table-column>-->
        <!--        <el-table-column prop="payTime" align="center" label="下单时间"></el-table-column>-->
        <el-table-column prop="dailTime" align="center" label="最近拨打时间"/>
        <el-table-column align="center" prop="name" label="操作" fixed="right" width="330">
          <template slot-scope="scope" v-if="!loading">
<!--            <el-button type="primary" size="mini" @click="showTelephone(scope.row.id)">-->
<!--              查看手机-->
<!--            </el-button>-->
            <el-tooltip class="item" effect="dark" content="直呼拨打" placement="top" v-if="callPanshi.qrcode === 'ON'">
              <el-button v-if="callPanshi.qrcode === 'ON'" type="success" size="mini"
                         @click="calling($event,scope.row.id)">直呼
              </el-button>
            </el-tooltip>
            <el-button hidden type="danger" size="mini" @click="hangup($event)">挂断</el-button>
            <el-button hidden type="warning" size="mini" @click="haveCalling($event,scope.row.id)">已拨打</el-button>
            <el-tooltip class="item" effect="dark" content="双呼拨打" placement="top" v-if="callDouble.qrcode === 'ON'">
              <el-button v-if="callDouble.qrcode === 'ON'" type="success" size="mini"
                         @click="callDoubleing($event,scope.row.id)">双呼
              </el-button>
            </el-tooltip>
            <el-button type="primary" size="mini" @click="getNewOpportunity($event,scope.row.id)">领取</el-button>

            <el-popover placement="bottom" width="400" :ref="`popover-${scope.$index}`" trigger="click"
                        @show="handlePopoverShow">
              <el-form ref="frozenFrom" :model="frozenForm">
                <el-form-item label="">
                  <el-radio-group v-model="frozenForm.time">
                    <el-radio label="7" size="mini" border>7天</el-radio>
                    <el-radio label="30" size="mini" border>一个月</el-radio>
                    <el-radio label="90" size="mini" border>三个月</el-radio>
                    <el-radio label="365" size="mini" border>一年</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item>
                  <el-checkbox-group size="mini" v-model="frozenForm.shortcut" @change="handleCheckbox">
                    <el-checkbox label="学员已愤怒" border></el-checkbox>
                    <el-checkbox label="再拨打投诉" border></el-checkbox>
                    <el-checkbox label="空号" border></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item>
                  <el-input type="textarea" maxlength="15" :clearable="true" placeholder="请输入冻结原因"
                            v-model="frozenForm.remark"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="mini" @click="handleFrozenFormSubmit(scope,scope.row.id)">提交
                  </el-button>
                  <el-button size="mini" @click="handleFrozenFormClose(scope)">取消</el-button>
                </el-form-item>
              </el-form>

              <el-button :ref="`frozen-${scope.$index}`" class="frozen-button"
                         slot="reference" type="info" size="mini">
                冻结
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <ShowTelephone :opportunity-list="showTelephoneObj.opportunityList"
                   :show-obj="showTelephoneObj.showObj">

    </ShowTelephone>
  </el-row>

</template>

<script>
import * as api from "../../utils/api";
import GgdCard from "@/components/GgdCard";
import {getUserId, getUserName} from "../../utils/auth";
import ShowTelephone from "@/views/opportunity/components/ShowTelephone";
import {receiveOpportunity} from "../../utils/api";


export default {
  name: "Stock",
  components: {
    GgdCard,
    ShowTelephone
  },
  data() {
    return {
      backStoreTimeStr: [],
      dailTimeArr: [],
      showTelephoneObj: {
        opportunityList: [],//查看手机号
        showObj: {
          isShow: false
        }
      },
      stockTableData: [],
      frozenForm: {
        shortcut: [],
        time: "",
        remark: ""
      },
      dayAccount: 0, //今日流水
      dayOrder: 0, //今日成单
      sumTime: "00:00:00", //拨打时长
      avgTime: "",//平均单通话时长
      dailUp: 0, //今日拨打量
      stockCount: 0,  //库存数
      newClient: 0,//首咨数
      stockTotal: 0, //库存总量
      loading: true,
      callPanshi: {},//磐石云外呼按钮
      uploadTime: [],
      callDouble: {},//双呼外呼按钮,
      // 搜索
      searchData: {
        possessId: '',
        classify: ''
      },
      possessList: [],
      pondId: 1,
    }
  },
  methods: {
      formateTime(time) {
          if (time < 1) {
              return '00:00:00'
          }
          const h = parseInt(time / 3600)
          const minute = parseInt(time / 60 % 60)
          const second = Math.ceil(time % 60)

          const hours = h < 10 ? '0' + h : h
          const formatSecond = second > 59 ? 59 : second
          return `${hours > 0 ? `${hours}:` : '00:'}${minute < 10 ? '0' + minute : minute}:${formatSecond < 10 ? '0' + formatSecond : formatSecond}`
      },
    showTelephone(idArr) {
      api.showTelephone([idArr]).then(res => {
        if (res.success) {
          this.showTelephoneObj.opportunityList = res.data
          this.showTelephoneObj.showObj.isShow = true
        } else {
          this.$message.warning("查看手机号失败")
        }
      }).catch(error => {
        this.$message.error("查看手机号失败");
      })
    },
    /**
     * 获取唯一配置列表
     */
    getPossessList: function () {
      api.possessList().then(res => {
        if (res.success) {
          this.possessList = res.data
        }
      })
    },
    zebj() {
      var dataKey = document.cookie.replace(/(?:(?:^|.*;\s*)dataKey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
      if (dataKey === 'zhengerbajing') {
        return true
      }
      return false
    },
    /**
     * 获取头部信息
     */
    getBaseInformation() {
      api.baseInformation().then(res => {
        if (res.success) {
            let resEntity = res.data
            this.avgTime = resEntity.calledEffective
            this.dayOrder = resEntity.orderSuccess
            this.dayAccount = resEntity.revenue
            this.sumTime = resEntity.calledTotal
            this.dailUp = resEntity.called
            this.stockCount = resEntity.receiveInventory
            this.newClient = resEntity.receiveFirst
            this.repairAmount = resEntity.repairRevenue
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.error("获取学习顾问信息失败");
      })
    },
    findRepertoryList() {
      this.loading = true
      if (this.uploadTime && this.uploadTime.length > 0) {
        this.searchData['createTimeStart'] = this.uploadTime[0]
        this.searchData['createTimeEnd'] = this.uploadTime[1]
      }
      if (this.backStoreTimeStr && this.backStoreTimeStr.length > 0) {
        this.searchData['backStoreTimeStart'] = this.backStoreTimeStr[0]
        this.searchData['backStoreTimeEnd'] = this.backStoreTimeStr[1]
      }
      if (this.dailTimeArr && this.dailTimeArr.length > 0) {
        this.searchData['dailTimeStart'] = this.dailTimeArr[0]
        this.searchData['dailTimeEnd'] = this.dailTimeArr[1]
      }
      this.searchData['pondId'] = this.pondId
      api.findRepertoryList(this.searchData).then(res => {
        if (res.success) {
          this.stockTableData = res.data
        } else {
          this.$message.warning(res.msg)
        }
        this.loading = false
      }).catch(error => {
        this.$message.error("获取库存列表失败");
      })
    },
    /**
     * 获取库存列表
     */
    getRepertoryListStore() {
      this.loading = true
      api.repertoryListStore({'pondId': this.pondId}).then(res => {
        if (res.success) {
          this.stockTableData = res.data
        } else {
          this.$message.warning(res.msg)
        }
        this.loading = false
      }).catch(error => {
        this.$message.error("获取库存列表失败");
      })
    },
    /**
     * 获取配置
     */
    Index() {
      api.index().then(res => {
        console.log(res, '获取配置')
        this.callPanshi = res.data.callPanshi;
        this.callDouble = res.data.callDouble;
      })
    },
    /**
     * 双呼拨打
     */
    callDoubleing(e, id) {
      api.doubleCall(id).then(res => {
        if (res.success) {
          //隐藏当前点击的拨打按钮，显示挂断按钮
          e.target.innerText = '已呼叫'
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.warning("拨打失败");
      })
    },
    /**
     * 拨打
     */
    calling(e, id) {
      api.calling(id).then(res => {
        if (res.success) {
          //隐藏当前点击的拨打按钮，显示挂断按钮
          let target = e.target
          let tagName = target.tagName
          if (tagName === 'SPAN') {
            target = target.parentNode
          }
          let next = target.nextSibling;
          target.setAttribute("hidden", true)
          next.removeAttribute("hidden")
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.error("拨打失败");
      })
    },
    /**
     * 挂断
     */
    hangup(e) {
      api.hangUp().then(res => {
        if (res.success) {
          //隐藏当前点击的挂断按钮，显示拨打按钮
          let target = e.target
          let tagName = target.tagName
          if (tagName === 'SPAN') {
            target = target.parentNode
          }
          let next = target.nextSibling;
          target.setAttribute("hidden", true)
          next.removeAttribute("hidden")
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.error("挂断失败");
      })
    },
    /**
     * 已拨打
     */
    haveCalling(e, id) {
      api.calling(id, 2).then(res => {
        if (res.success) {
          //隐藏当前点击的挂断按钮，显示拨打按钮
          let target = e.target
          let tagName = target.tagName
          if (tagName === 'SPAN') {
            target = target.parentNode
          }
          let prev = target.previousSibling;
          target.setAttribute("hidden", true)
          prev.removeAttribute("hidden")
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.error("拨打失败");
      })
    },
    /**
     *
     * @param e 触发事件
     * @param id 机会Id
     * @param type 类型 1 拨打 2 领取 3 冻结
     */
    getNewOpportunity(e, id) {
      api.receiveOpportunity(id, 0).then(res => {
        if (res.success) {
          let target = e.target
          target.innerText = "已领取"
          this.stockCount++
          this.$message.success(res.msg)
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.error("领取失败");
      })
    },

    /**
     * 冻结
     * @param e
     * @param id
     */
    frozen(id, freezeTime, params, scope) {
      api.frozen(id, freezeTime, 2, params).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.handleFrozenFormClose(scope)
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.error("冻结失败");
      })
      scope._self.$refs[`frozen-${scope.$index}`].disabled = true
    },
    /**
     * 首咨与库存的显示数量
     */
    statisticsStock: function () {
      api.statisticsStock(this.pondId).then(res => {
        if (res.success) {
          let resEntity = res.data
          this.stockTotal = resEntity['4']
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.error("获取库存与首咨数量失败");
      })
    },
    reload() {
      this.getRepertoryListStore()
    },
    toHome() {
      this.$router.back()
      // this.$router.push({name: 'Consultant'})
    },
    /**
     *
     */
    handleCheckbox() {
      let shortcut = this.frozenForm.shortcut
      if (shortcut) {
        this.frozenForm.remark = shortcut.toString()
      } else {
        this.frozenForm.remark = ""
      }
    },
    /**
     * popover显示时触发
     */
    handlePopoverShow() {
      this.frozenForm = {
        shortcut: [],
        time: "",
        remark: ""
      }
    },
    /**
     * 冻结表单提交
     */
    handleFrozenFormSubmit(scope, opporId) {
      if (!this.frozenForm.time) {
        this.$message.warning("未选择冻结时间")
        return
      }
      if (!this.frozenForm.remark) {
        this.$message.warning("未填写冻结原因")
        return
      }
      if (this.frozenForm.remark.length > 15) {
        this.$message.warning("冻结原因字数不可超过15个字")
        return
      }

      this.frozenForm.opportunityId = opporId
      this.frozenForm.userId = getUserId()
      this.frozenForm.applicant = getUserName()
      this.frozen(opporId, this.frozenForm.time, this.frozenForm, scope)
    },
    handleFrozenFormClose(scope) {
      scope._self.$refs[`popover-${scope.$index}`].doClose()
    }

  },
  computed: {
    /**
     * 领取机会总数
     * @returns {number}
     */
    opporTotalCount: function () {
      return this.newClient + this.stockCount
    },
  },
  mounted() {
    if (this.$route.params.pondId) {
      this.pondId = this.$route.params.pondId
    }
    this.getBaseInformation()
    this.getRepertoryListStore()
    this.statisticsStock()
    this.Index()
    this.getPossessList()
  }

}
</script>

<style scoped lang="less">
.form-query .el-form-item{
  margin-right: 0px!important;
  margin-bottom:0px!important;
}

.custom--top-search-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  .search-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
  }

  .search-label {
    font-size: 12px;
    margin-right: 10px;
    width: 120px;
  }

  .search-input {
    width: 150px;
    text-align: left;
  }

  .search-btn {
    margin-left: 20px;
  }
}

.custom--top-btn-wrapper {
  .left-btn-group {
    width: 550px;
  }

  .right-btn-group {
    flex: 1;
    width: auto;
  }

  .custom--GgdCard {
    width: 160px;
  }
}


/deep/ .el-button--success {
  color: #FFF;
  background-color: #2f8e00;
  border-color: #2f8e00;
}

.frozen-button {
  margin-left: 10px;
}

/deep/ .el-radio {
  margin-right: 0;
}

</style>
